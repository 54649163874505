// Override default variables before the import
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&subset=latin-ext");
body {
  font-family: "Source Sans Pro", sans-serif !important;
}

$body-bg: #f5f4f4;

.navbar-default {
  position: fixed;
  width: 100%;
  z-index: 999;
}
.navbar-scroll {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 200ms linear;
}
#tipsContainer > img {
  width: 100% !important;
}

#tipsContainer > p {
  width: 100% !important;
  display: block !important;
  background-color: transparent !important;
}

#tipsContainer > p > span {
  width: 100% !important;
  display: block !important;
  overflow-wrap: break-word !important;
  background-color: transparent !important;
}

#tipsContainer > iframe {
  width: 100% !important;
}

@media only screen and (max-width: 991px) {
  .responsiveCustomMenu {
    flex-direction: column !important;
  }
}

@media only screen and (min-width: 992px) {
  .responsiveCustomMenu {
    flex-direction: row !important;
  }
}
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
