body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 990px) {
  .manuBgColor {
    background-color: black;
  }
}
